.EditModalActions {
  display: flex;
  justify-content: space-between;
  padding: clamp(5px, 5%, 20px);
  padding-top: 0;
}
.EditModalButton {
  margin-left: 1px;
}
.EditModalButtonContainer {
  display: flex;
}
